import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Spin, Tree } from "antd";
import styled from "../../styles/modals/modals.module.scss";
import { imageAPI } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { getFiles, getFolders } from "../../redux/selectors/imagesSelectors";
import { requestFiles, requestTree } from "../../redux/actions/imagesAction";
import Upload from "antd/es/upload";
import { setMessages } from "../../redux/reducers/errorsReducer";

const { DirectoryTree } = Tree;

const RightModal = (props) => {
	const {
		openRight,
		handleCloseRight,
		nameFolder,
		setNameFolder,
		addFolder,
		activePath,
		setActivePath,
		action,
		deleteFolder,
	} = props;

	return (
		<div
			className={`${styled.rightModal} ${openRight ? styled.right_modal_open : ""}`}
		>
			<div className={styled.hideModal} onClick={handleCloseRight}>
				<div className={styled.icon}>
					<span className="f-icon icon-chevron-right-circle fs-15" />
				</div>
				<div className={styled.text}>скрыть</div>
			</div>
			{action === "create" ? (
				<div className={styled.textFieldFolder}>
					<div className={styled.label}>Директория</div>
					<Input
						value={activePath}
						onChange={(e) => setActivePath(e.target.value)}
						style={{ width: "300px" }}
					/>
					<div className={styled.label}>Введите название папки</div>
					<Input
						onChange={(e) => setNameFolder(e.target.value)}
						style={{ width: "300px" }}
					/>
					<div className={styled.buttonFolder}>
						<Button type="primary" onClick={addFolder}>
							Создать
						</Button>
					</div>
				</div>
			) : (
				<div className={styled.deleteFolder}>
					<div className={styled.title}>Удаление "{nameFolder}"</div>
					<div className={styled.question}>
						Вы действительно хотите удалить выбранную папку?
					</div>
					<div className={styled.buttons}>
						<Button
							type="primary"
							onClick={(e) => deleteFolder(e, activePath, "folder")}
						>
							Подтверждаю
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default function ModalFileManager({
	open,
	handleClose,
	setNewImage,
	closeModalWhenConfirm,
}) {
	const [activePath, setActivePath] = useState("/");
	const [openRight, setOpenRight] = useState(false);
	const [isLoadingMain, setLoadingMain] = useState(false);
	const [nameFolder, setNameFolder] = useState("");
	const [activeImage, setActiveImage] = useState({});
	const [actionRightBar, setActionRightBar] = useState("");
	const [selectFullImgData, setSelectFullImgData] = useState({});
	const [loading, setLoading] = useState(false);
	const [aspectRatio, setAspectRatio] = useState(null);

	const hierarchy = useSelector((state) => getFolders(state));
	const files = useSelector((state) => getFiles(state));

	const closeModal = () => {
		handleClose();
		setOpenRight(false);
		setSelectFullImgData({});
	};

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestTree());
		dispatch(requestFiles("/"));
	}, []);

	const getStandardAspectRatioFromUrl = (url) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = url;

			img.onload = () => {
				const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b)); // Рекурсивный НОД
				const divisor = gcd(img.width, img.height); // НОД для ширины и высоты

				const rawAspectRatio = {
					width: img.width / divisor,
					height: img.height / divisor,
				};

				const standardRatios = [
					{ width: 16, height: 9 }, // Широкоформатный
					{ width: 4, height: 3 }, // Стандартный
					{ width: 1, height: 1 }, // Квадратный
					{ width: 3, height: 2 }, // Популярный фотографический
					{ width: 8, height: 5 }, // Часто используется в картах
					{ width: 5, height: 4 }, // Стандарт печати
					{ width: 21, height: 9 }, // Кинематографический
					{ width: 18, height: 9 }, // Альтернатива 16:9
					{ width: 2, height: 1 }, // Широкий формат
					{ width: 3, height: 1 }, // Суперширокий
					{ width: 9, height: 16 }, // Портретный режим для мобильных
					{ width: 10, height: 7 }, // Популярный для рамок и альбомов
					{ width: 7, height: 5 }, // Стандартный альбомный
					{ width: 4, height: 5 }, // Портретный вариант
				];

				const closestRatio = standardRatios.reduce((prev, curr) => {
					const prevDiff = Math.abs(
						rawAspectRatio.width / rawAspectRatio.height -
							prev.width / prev.height
					);
					const currDiff = Math.abs(
						rawAspectRatio.width / rawAspectRatio.height -
							curr.width / curr.height
					);
					return currDiff < prevDiff ? curr : prev;
				});

				resolve({
					w: closestRatio.width,
					h: closestRatio.height,
				});
			};

			img.onerror = () => {
				reject(new Error("Не удалось загрузить изображение."));
			};
		});
	};

	const fetchAspectRatio = async (url) => {
		try {
			return await getStandardAspectRatioFromUrl(url);
		} catch (error) {
			console.error("Error fetching aspect ratio:", error.message);
			return null;
		}
	};

	const copyToClipboard = (text) => {
		try {
			const textArea = document.createElement("textarea");
			textArea.value = text;
			textArea.style.position = "fixed";
			textArea.style.left = "-99999px";
			document.body.appendChild(textArea);
			textArea.select();
			try {
				document.execCommand("copy");
				console.info("success copy to clipboard (NATIVE)");
			} catch (err) {
				console.error("Ошибка при копировании с fallback: ", err);
			} finally {
				document.body.removeChild(textArea);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const deleteFolder = async (e, path, type) => {
		e.stopPropagation();
		const { api_success, api_messages } = await imageAPI.deleteFolder(
			path,
			type
		);
		if (api_success) {
			if (type === "file") {
				dispatch(requestFiles(activePath));
			}
			if (type === "folder") {
				dispatch(requestFiles("/"));
				setActivePath("/");
				dispatch(requestTree());
				setOpenRight(false);
			}
		}
		setMessagesRedux(api_messages);
	};

	const onSelect = async (keys, info) => {
		setLoadingMain(true);
		setActivePath(keys[0]);
		setNameFolder(info.node.name);
		await dispatch(requestFiles(keys[0]));
		setLoadingMain(false);
	};

	const addFolder = async () => {
		const { api_messages } = await imageAPI.createFolder(
			activePath ? activePath : "/",
			nameFolder
		);
		setMessagesRedux(api_messages);
		setOpenRight(false);
		dispatch(requestTree());
	};

	const handleCloseRight = () => {
		setOpenRight(false);
	};

	const actionFolder = (action) => {
		setActionRightBar(action);
		handleOpenRight();
	};

	const handleOpenRight = () => {
		setOpenRight(true);
	};

	const sendFile = async (file) => {
		setLoading(true);
		const { api_messages } = await imageAPI.uploadFile(activePath, file);
		setMessagesRedux(api_messages);
		dispatch(requestFiles(activePath));
		setLoading(false);
	};

	const setMessagesRedux = (val) => {
		dispatch(setMessages(val));
	};

	useEffect(() => {
		if (activeImage) {
			const fetchAspectRatio = async () => {
				try {
					const ratio = await getStandardAspectRatioFromUrl(
						activeImage?.url
					);
					setAspectRatio(ratio);
				} catch (error) {
					console.error("Error fetching aspect ratio:", error.message);
				}
			};

			if (activeImage?.url) fetchAspectRatio();
		}

		return () => {
			setAspectRatio(null);
		};
	}, [activeImage]);

	return (
		<div>
			<Modal
				open={open}
				onOk={() => {
					if (setNewImage) setNewImage(activeImage);
				}}
				onCancel={closeModal}
				title="Файловый менеджер"
				width={850}
				closeIcon={<span className="f-icon icon-cross fs-17" />}
				bodyStyle={{ paddingTop: "0" }}
				footer={[
					<div className={styled.footerFM}>
						{/** **********Футер************ */}

						<div className={styled.left}>
							<div className="custom-ant mr-10">
								<Button
									type="primary"
									ghost
									icon={
										<span className="f-icon icon-plus fs-11 mr-7" />
									}
									onClick={() => actionFolder("create")}
									className="fs-13"
								>
									Создать папку
								</Button>
							</div>
							<div className="custom-ant">
								<Button
									type="danger"
									icon={
										<span className="f-icon icon-trash fs-13 mr-7" />
									}
									onClick={() => actionFolder("delete")}
									className="fs-13"
								>
									Удалить папку
								</Button>
							</div>
						</div>
						<div className={styled.right}>
							<div className="custom-ant d-flex">
								{selectFullImgData && (
									<>
										<Button
											type="outline"
											icon={
												<span className="f-icon icon-copy fs-13 mr-7" />
											}
											className={"fs-13"}
											onClick={() => {
												copyToClipboard(selectFullImgData?.url);
											}}
										>
											local (url)
										</Button>
										<Button
											type="outline"
											icon={
												<span className="f-icon icon-launch fs-13 mr-7" />
											}
											className={"fs-13"}
											onClick={() => {
												copyToClipboard(
													selectFullImgData?.public_url
												);
											}}
										>
											prod (url)
										</Button>
										<Button
											type="outline"
											icon={
												<span className="f-icon icon-launch fs-13 mr-7" />
											}
											className={"fs-13"}
											onClick={() => {
												copyToClipboard(selectFullImgData?.path);
											}}
										>
											path
										</Button>
									</>
								)}
								<Button
									type="primary"
									icon={
										<span className="f-icon icon-launch fs-13 mr-7" />
									}
									onClick={async () => {
										if (setNewImage) {
											setNewImage({
												...activeImage,
												aspectRatio: aspectRatio ?? null,
											});
										}
										if (closeModalWhenConfirm) closeModal();
									}}
									className="fs-13"
								>
									Выбрать файл
								</Button>
							</div>
						</div>
					</div>,
				]}
			>
				{/** **********Шапка************ */}

				<div className={styled.headerFM}>
					<div className={styled.path}>Путь: {activePath}</div>
					<div className={styled.buttonsHead}>
						<div className={styled.addImage}>
							<Upload
								beforeUpload={(file) => {
									sendFile(file);
									return false;
								}}
								showUploadList={false}
								maxCount={1}
							>
								<div className="custom-ant">
									<Button
										type="primary"
										ghost
										icon={
											<span className="f-icon icon-plus fs-11 mr-7" />
										}
										className="fs-13"
									>
										{loading ? "Loading..." : "Загрузить файл"}
									</Button>
								</div>
							</Upload>
						</div>
					</div>
				</div>
				<div className={styled.borderFM} />

				{/** **********Основное************ */}

				<div className={styled.hierarchy}>
					{/** **********Папки************ */}

					<div className={styled.folders}>
						<DirectoryTree
							multiple
							defaultExpandAll
							onSelect={onSelect}
							treeData={hierarchy}
							switcherIcon={<span className="icon-chevron-down fs-11" />}
							fieldNames={{
								key: "path",
								children: "items",
								title: "name",
							}}
							icon={<span className="icon-folder fs-11" />}
						/>
					</div>

					{/** **********Фото************ */}

					<div className={styled.files}>
						{files?.length ? (
							files.map((item, key) => (
								<div
									key={key}
									className={`${styled.imageWrapper} ${item.url === activeImage?.url ? styled.active : ""}`}
								>
									<div className={styled.close}>
										<span
											className="f-icon icon-cross fs-17"
											onClick={(e) =>
												deleteFolder(e, item.path, "file")
											}
										/>
									</div>
									<div className={`${styled.container}`}>
										<div
											className={styled.image}
											onClick={() => {
												setActiveImage({
													url: item.url,
													path: item.path,
													info: item.info,
												});
												setSelectFullImgData(item);
											}}
										>
											<img src={item.url} alt="" />
										</div>
										<div className={styled.name}>{item.name}</div>
									</div>
								</div>
							))
						) : (
							<div className={styled.emptyFolder}>
								<div>Папка пуста...</div>
							</div>
						)}
						<div
							className={`${styled.loadingMain} ${isLoadingMain || loading ? "" : "hide"}`}
						>
							<div className="example">
								<Spin size="large" />
							</div>
						</div>
					</div>

					{/** **********Правая модалка************ */}

					<RightModal
						openRight={openRight}
						handleCloseRight={handleCloseRight}
						nameFolder={nameFolder}
						setNameFolder={setNameFolder}
						addFolder={addFolder}
						activePath={activePath}
						setActivePath={setActivePath}
						action={actionRightBar}
						deleteFolder={deleteFolder}
					/>
				</div>
			</Modal>
		</div>
	);
}
