import React from "react";
import { Button, Input } from "antd";

/**
 * @author Zholaman Zhumanov
 * @created 14.11.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function SettingLineHeight(props) {
	const { changeParams, isReplacement, updateCallback, lineHeightValue } =
		props;

	return (
		<div style={{ marginTop: 7 }}>
			<Input
				defaultValue={lineHeightValue}
				size="small"
				type="number"
				onChange={(e) => {
					if (changeParams)
						changeParams(
							"styles",
							{ lineHeight: parseFloat(e.target.value) },
							isReplacement
						);
					if (updateCallback)
						updateCallback({ lineHeight: parseFloat(e.target.value) });
				}}
				style={{ marginBottom: 15 }}
			/>
		</div>
	);
}

export default SettingLineHeight;
