import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, InputNumber, Modal, Select } from "antd";
import uuid from "react-uuid";
import { setComponentCollection } from "../../redux/reducers/editorReducer";
import { editorAPI } from "../../api/api";

export default function ModalCategoryBloks({ open, handleClose, name }) {
	const dispatch = useDispatch();

	const [count, setCount] = useState(10);
	const [currentShop, setCurrentShop] = useState("kz");
	const [lang, setLang] = useState(1);
	const [shop, setShop] = useState(1);
	const [category, setCategory] = useState("new");
	const [params, setParams] = useState({});
	const [shops, setShops] = useState([]);
	const [categories, setCategories] = useState([]);

	const getParams = async () => {
		let myHeaders = new Headers();
		myHeaders.append(
			"User-Agent",
			"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
		);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		const res = await fetch("https://api.lichi.com/site/info", requestOptions)
			.then((response) => response.json())
			.then((result) => result.api_data.data.info.shop)
			.catch((error) => console.error("error", error));
		setParams(res);
	};
	const getShops = async () => {
		const res = await editorAPI.getShops();
		if (res.api_success) {
			setShops(res.list);
		}
	};
	const getCategories = async () => {
		let myHeaders = new Headers();
		myHeaders.append(
			"User-Agent",
			"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
		);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		const res = await fetch(
			`https://api.lichi.com//category/get_category_list?lang=${lang}&shop=${shop}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => result)
			.catch((error) => console.error("error", error));
		setCategories(res.api_data.aData);
	};

	useEffect(() => {
		getParams();
		getShops();
	}, []);

	useEffect(() => {
		if (shop) {
			getCategories();
		}
	}, [shop]);

	useEffect(() => {
		setShop(params[currentShop]?.id);
		setLang(params[currentShop]?.language_def_id);
	}, [currentShop, params]);

	const createNewComponent = () => {
		let component = {
			name,
			id: uuid(),
			count,
			lang,
			shop,
			category,
			link: {
				isActive: true,
				out: false,
				path: `https://lichi.com/ru/ru/category/${category}`,
				name: category,
			},
			view: {
				wholesale: true,
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			styles: {
				marginBottom: "2px",
				spaceBetween: 2,
				background: "#ffffff",
				backgroundDark: "#181a1b",
				padding: ["0px", "0px", "0px", "0px"],
			},
			animate: {
				showFlash: true,
			},
			blockTheme: false,
			randomShowComponent: false,
			elementOutside: true,
			slidePerView: 5.3,
			slidePerGroup: 4,
			isAutoplay: false,
			autoplay: {
				speed: 700,
				delay: 0,
			},
			nameStyles: {
				margin: ["0px", "0px", "0px", "0px"],
				color: "#000000",
				colorDark: "#ffffff",
				fontFamily: "Futura PT",
				fontWeight: 300,
				fontStyle: "normal",
				fontSize: "14px",
				textAlign: "left",
			},
			priceStyles: {
				margin: ["0px", "0px", "0px", "0px"],
				color: "#000000",
				colorDark: "#ffffff",
				fontFamily: "Futura PT",
				fontWeight: 300,
				fontStyle: "normal",
				fontSize: "14px",
				textAlign: "left",
			},
		};
		dispatch(setComponentCollection(component));
		handleClose();
	};

	const layout = {
		labelCol: {
			span: 14,
		},
		wrapperCol: {
			span: 10,
		},
	};

	return (
		<div>
			<Modal
				visible={open}
				onOk={createNewComponent}
				onCancel={handleClose}
				title={name}
				okText="Добавить"
				cancelText="Отмена"
				closeIcon={<span className="f-icon icon-cross fs-17" />}
			>
				<Form {...layout} style={{ margin: "0 70px 0 0" }}>
					<Form.Item
						style={{ marginBottom: "15px" }}
						name="country"
						label="Укажите страну"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Select
							style={{ width: "100%" }}
							defaultValue={currentShop}
							onChange={(e, item) => setCurrentShop(item.value)}
						>
							{shops.map((item, key) => (
								<Select.Option key={key} value={item.id}>
									{item.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: "15px" }}
						name="category"
						label="Под категории"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Select
							style={{ width: "100%" }}
							defaultValue={category}
							onChange={(e, item) => setCategory(item.value)}
						>
							{categories?.length &&
								categories.map((item, key) => {
									return (
										<>
											<Select.Option key={item.url} value={item.url}>
												{item.name}
											</Select.Option>
											{item?.items.map((subcategory, key) => (
												<Select.Option
													key={subcategory.url}
													value={subcategory.url}
												>
													{subcategory.name}
												</Select.Option>
											))}
										</>
									);
								})}
							<Select.Option value={"blackfriday"}>
								blackfriday
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						style={{ marginBottom: "15px" }}
						name="count"
						label="Укажите кол-во блоков"
						rules={[
							{
								required: true,
							},
						]}
					>
						<InputNumber
							style={{ width: "100%" }}
							defaultValue={count}
							onChange={(val) => setCount(val)}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
